






















































import AssessmentSettingSelectField from "@/components/settings/assessment/AssessmentSettingSelectField.vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import {
  GET_COMPANY_DETAILS,
  SET_COMPANY
} from "@/store/modules/auth/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import {
  INCEPTION_STATS,
  UPDATE_COMPANY_SETTING
} from "@/store/modules/recruiter/constants";
import {
  CompanySettingTypes,
  UpdateCompanySettingPayload
} from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { AssessmentSettingSelectField, GoBackHeader },
  name: "AssessmentsSetting",
  data() {
    return {
      candidate_eng: "",
      candidate_annotation: "",
      admin_eng: "",
      admin_annotation: "",
      eng_assessment_items: [
        {
          title: "English V1",
          value: "english_v1"
        },
        {
          title: "English V2",
          value: "english_v2"
        }
      ],
      annotation_assessment_items: [
        {
          title: "Annotation V1",
          value: "annotation_v1"
        },
        {
          title: "Annotation V2",
          value: "annotation_v2"
        }
      ]
    };
  },
  created() {
    const assessment_setting =
      this.get_company_details.s3_path.assessment_setting;
    this.candidate_eng = assessment_setting.candidates_english;
    this.candidate_annotation = assessment_setting.candidates_annotation;
    this.admin_eng = assessment_setting.admin_english;
    this.admin_annotation = assessment_setting.admin_annotation;
  },
  computed: {
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    }),
    AppAssessments() {
      return AppAssessments;
    }
  },
  methods: {
    ...mapActions("recruiter", {
      update_company_setting: UPDATE_COMPANY_SETTING
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapMutations("recruiter", {
      set_inception_stats: INCEPTION_STATS
    }),
    ...mapMutations("auth", {
      set_company: SET_COMPANY
    }),
    // Function to update company assessment setting
    async update_value({
      type,
      value
    }: {
      type: AppAssessments.CurrentAssessmentType;
      value: AppAssessmentNames;
    }) {
      // API Call
      const payload: UpdateCompanySettingPayload = {
        current_assessment: value,
        current_assessment_type: type,
        type: CompanySettingTypes.ASSESSMENT_SETTING
      };
      const response = await this.update_company_setting(payload);
      if (response) {
        this.set_company(response);
        this.set_inception_stats({
          users: "0",
          englishAssessment: "0",
          annotationAssessment: "0"
        });
        this.set_root_notification(
          this.$t("recruiter.settings.assessment.update")
        );
      }
    }
  }
});
